import React, { RefObject } from 'react';
import { OverlayProps } from '../types/overlay';
import './overlay.css';
import { ReactComponent as ExploreSVG } from '../icon/mouse-control-explore.svg';
import { ReactComponent as SelectSVG } from '../icon/mouse-control-select.svg';
import { ReactComponent as ZoomSVG } from '../icon/mouse-control-zoom.svg';
import { ReactComponent as MobileExploreSVG } from '../icon/touch-control-explore.svg';
import { ReactComponent as MobileSelectSVG } from '../icon/touch-control-select.svg';
import { ReactComponent as MobileZoomSVG } from '../icon/touch-control-zoom.svg';
import { ReactComponent as AliveVisionSVG } from '../svg/alive_vision_by_3decide_whiteLogomarca.svg';
import { ReactComponent as AliveVisionArtSVG } from '../svg/alive_vision_arts&Culture_whiteLogomarca.svg';
import { ReactComponent as AliveVisionBusinessSVG } from '../svg/alive_vision_businessCenters_whiteLogomarca.svg';
import { ReactComponent as AliveVisionBusinessSchoolSVG } from '../svg/alive_vision_businessSchools_whiteLogomarca.svg';
import { ReactComponent as AliveVisionCulturalSVG } from '../svg/alive_vision_culturalHeritage_whiteLogomarca.svg';
import { ReactComponent as AliveVisionECommerceSVG } from '../svg/alive_vision_eCommerce_whiteLogomarca.svg';
import { ReactComponent as AliveVisionEventsSVG } from '../svg/alive_vision_events_whiteLogomarca.svg';
import { ReactComponent as AliveVisionIndustrySVG } from '../svg/alive_vision_Industry_whiteLogomarca.svg';
import { ReactComponent as AliveVisionNaturalParksSVG } from '../svg/alive_vision_naturalParks_whiteLogomarca.svg';
import { ReactComponent as AliveVisionRealEstateSVG } from '../svg/alive_vision_realEstate_whiteLogomarca.svg';
import { ReactComponent as AliveVisionShoppingSVG } from '../svg/alive_vision_shoppings_whiteLogomarca.svg';
import { ReactComponent as AliveVisionSmartCitiesSVG } from '../svg/alive_vision_smartCities_whiteLogomarca.svg';
import { ReactComponent as AliveVisionTourismSVG } from '../svg/alive_vision_tourism_whiteLogomarca.svg';
import { ReactComponent as AliveVisionWeddingsSVG } from '../svg/alive_vision_weddings_whiteLogomarca.svg';
import { ReactComponent as AliveVisionNaturalSVG } from '../svg/alive-vision-logo-tagline.svg';
import { ReactComponent as AliveVisionStandsSVG } from '../svg/alive_vision_stands&showrooms_whiteLogomarca.svg';
import { ReactComponent as MainLogoSVG } from '../svg/3decide-logo.svg';
import { Language } from './language';
import { svgRecolor } from '../libs/utils';
import overlayLanguage from '../languages/overlay.json';

export default class Overlay extends React.Component<OverlayProps> {
  exploreRef: RefObject<SVGSVGElement> = React.createRef();
  selectRef: RefObject<SVGSVGElement> = React.createRef();
  zoomRef: RefObject<SVGSVGElement> = React.createRef();

  componentDidMount = () => {
    if (this.exploreRef.current) {
      svgRecolor(this.exploreRef.current);
    }

    if (this.selectRef.current) {
      svgRecolor(this.selectRef.current);
    }

    if (this.zoomRef.current) {
      svgRecolor(this.zoomRef.current);
    }
  };

  render = () => {
    const className = this.props.active ? 'overlay inactive' : 'overlay';
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
    const exploreIcn = !mobile ? (
      //<img width="65%" height="auto" src={exploreIcon} alt=""></img>
      <ExploreSVG ref={this.exploreRef} />
    ) : (
      //<img width="50%" height="auto" src={exploreMIcon} alt=""></img>
      <MobileExploreSVG ref={this.exploreRef} style={{ width: 'auto', height: '40px' }} />
    );
    const selectIcn = !mobile ? (
      //<img width="32%" height="auto" src={selectIcon} alt=""></img>
      <SelectSVG ref={this.selectRef} />
    ) : (
      //<img width="33%" height="auto" src={selectMIcon} alt=""></img>
      <MobileSelectSVG ref={this.selectRef} style={{ width: 'auto', height: '40px' }} />
    );
    const zoomIcn = !mobile ? (
      //<img width="32%" height="auto" src={zoomIcon} alt=""></img>
      <ZoomSVG ref={this.zoomRef} />
    ) : (
      //<img width="50%" height="auto" src={zoomMIcon} alt=""></img>
      <MobileZoomSVG ref={this.zoomRef} style={{ width: 'auto', height: '40px' }} />
    );

    let lang = overlayLanguage.fr;
    if (this.props.language) {
      switch (this.props.language) {
        case 'English':
          lang = overlayLanguage.en;
          break;

        case 'German':
          lang = overlayLanguage.de;
          break;

        default:
          lang = overlayLanguage.pt;
          break;
      }
    }

    let info = lang.info;
    let explore = lang.explore;
    let select = lang.select;
    let zoom = lang.zoom;
    /*switch (this.props.language) {
      case 'English':
        info = mobile ? 'Tap to start your virtual tour' : 'Click to start your virtual tour';
        explore = 'Explore';
        select = 'Select';
        break;

      default:
        info = 'Clique para iniciar a tour virtual';
        explore = 'Explorar';
        select = 'Seleccionar';
        break;
    }*/

    let Svg = AliveVisionSVG;

    switch (this.props.aliveVisionSVG) {
      case 'natural':
        Svg = AliveVisionNaturalSVG;
        break;

      case 'arts-culture':
        Svg = AliveVisionArtSVG;
        break;

      case 'business-centers':
        Svg = AliveVisionBusinessSVG;
        break;

      case 'business-schools':
        Svg = AliveVisionBusinessSchoolSVG;
        break;

      case 'cultural':
        Svg = AliveVisionCulturalSVG;
        break;

      case 'ecommerce':
        Svg = AliveVisionECommerceSVG;
        break;

      case 'events':
        Svg = AliveVisionEventsSVG;
        break;

      case 'industry':
        Svg = AliveVisionIndustrySVG;
        break;

      case 'natural-parks':
        Svg = AliveVisionNaturalParksSVG;
        break;

      case 'real-estate':
        Svg = AliveVisionRealEstateSVG;
        break;

      case 'shoppings':
        Svg = AliveVisionShoppingSVG;
        break;

      case 'smart-cities':
        Svg = AliveVisionSmartCitiesSVG;
        break;

      case 'tourism':
        Svg = AliveVisionTourismSVG;
        break;

      case 'weddings':
        Svg = AliveVisionWeddingsSVG;
        break;

      case 'stands':
        Svg = AliveVisionStandsSVG;
        break;
    }

    return (
      <div>
        {this.props.changeLanguage && !this.props.active ? (
          <Language
            onLanguageSelect={this.props.changeLanguage}
            languageList={this.props.languages ? this.props.languages : []}
          />
        ) : null}
        <div className={className}>
          <div className="info">
            <div className="infoBox">
              <p>{info}</p>
              <div className="row">
                <div className="column">
                  <div className="img-wrapper">{exploreIcn}</div>
                  <p>{explore}</p>
                </div>
                <div className="column">
                  <div className="img-wrapper border">{selectIcn}</div>
                  <p>{select}</p>
                </div>
                <div className="column">
                  <div className="img-wrapper border">{zoomIcn}</div>
                  <p>{zoom}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="left"></div>
            <div className="center">
              <Svg />
            </div>
            <div
              className="right"
              style={this.props.showPartners ? { visibility: 'visible' } : { visibility: 'hidden' }}
            >
              <p>Partenaires</p>
              <div className="row">
                <MainLogoSVG />
                {/*<img width="88px" height="30px" src="../svg/3decide-logo.svg" alt=""></img>
              <img width="88px" height="41px" src="./assets/img/logo_partner.png" alt=""></img>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
