import React, { FunctionComponent } from 'react';
import './formPage.css';
import { ReactComponent as ForwardSVG } from '../icon/arrow_forward.svg';
//import { ReactComponent as AliveVisionSVG } from '../svg/alive_vision_by_3decide_whiteLogomarca.svg';

interface FormPageProps {
  imgSrc: string;
  logoSrc: string;
  title: string;
  description: string;
  click: CallableFunction;
  url: string;
  clientEmail: string;
  altCircuit: string;
}

const FormPage: FunctionComponent<FormPageProps> = ({
  imgSrc,
  logoSrc,
  title,
  description,
  click,
  url,
  clientEmail,
  altCircuit,
}) => {
  return (
    <div
      className="form-page"
      style={{
        height: '100vh',
        // backgroundImage: `url(${imgSrc})`,
      }}
    >
      <img className="f-pm-bg" src={imgSrc} alt=""></img>
      <div className="f-pm-focus"></div>
      <img onClick={() => window.location.reload()} className="f-pm-logo" src={logoSrc} alt="" />
      <h1 className="f-pm-title">{title}</h1>
      <p className="f-pm-desc">{description}</p>
      <div className="f-pm-buttons">
        <div className="f-pm-button" onClick={() => click()}>
          <p>Visiter un logement {altCircuit}</p>
          <ForwardSVG />
        </div>
      </div>
      <div className="contact-info">Contacts</div>
      <div className="email-info" onClick={() => window.open(`mailto:${clientEmail}`)}>
        pmatignon@senior-contract.com
      </div>
      <div className="person-info">Pierre Matignon, Chargé d’Affaires</div>
      <div className="phone-info">+33 6 7444 6107</div>
      <img className="f-pm-partner" src={'./assets/img/MediContractGroup_logo_2000px.jpg'} alt="" />
    </div>
  );
};

export default FormPage;
