import React from 'react';
import ReactGA from 'react-ga';
import './LandingPage.css';
import './LandingPage.desktop.css';
import './LandingPage.mobile.css';
import ExplorerContainer from './ExplorerContainer';
import { decodeHash, decodeNumber, getToken, hexToRgb } from '../libs/utils';
import Lobby from '../components/lobby';
import { LandingPageConfiguration } from '../types/landingPage';
import { io } from 'socket.io-client';
import { GlobalVars } from '../libs/utils';
import VideoChat from '../components/VideoChat';
import { ReactComponent as InfoSVG } from '../icon/info.svg';
//import { ReactComponent as ForwardSVG } from '../icon/arrow_forward.svg';
//import { ReactComponent as AliveVisionSVG } from '../svg/alive_vision_by_3decide_whiteLogomarca.svg';
//import { ReactComponent as CloseSVG } from '../icon/close.svg';
import { disable, enable } from '../libs/marzipano';
import FormPage from '../components/formPage';
import { MappingData } from '../types/mapping';
//import { Language } from '../components/language';
//import Marzipano from './MarzipanoViewer';
//import 'int_sdk_viewer3d/dist/index.css';
//const MyComponent = require('int_sdk_viewer3d').default;

interface LandingPageState {
  startingMapping: string | null;
  orientation: number | string;
  height: string;
  language: string | undefined;
  bgIndex?: number;
  description?: string;
  page: number;
  showMoreInfo: boolean;
  night?: boolean;
  live: boolean;
  lobby: boolean;
  controlling: boolean;
  videochat: boolean;
  user?: any;
  limitReached: boolean;
  blocked: boolean;
  currentMapping: string | null;
}

export default class LandingPage extends React.Component {
  height = '100vh';

  state: LandingPageState = {
    startingMapping: null,
    orientation: window.orientation,
    height: this.height,
    language: undefined,
    bgIndex: 0,
    page: 0,
    showMoreInfo: false,
    live: false,
    lobby: false,
    controlling: false,
    videochat: false,
    user: undefined,
    limitReached: false,
    blocked: false,
    currentMapping: null,
  };

  svgRef = React.createRef<SVGSVGElement>();
  vidRef = React.createRef<HTMLVideoElement>();

  componentDidMount = () => {
    let favicon: any = document.getElementById('favicon');
    if (favicon) favicon.href = './assets/img/valsteam_logo_color.png';
    sessionStorage.clear();
    const landingPageConfig: LandingPageConfiguration = require('../JSON/landing_page.json');
    document.title = landingPageConfig.webTitle;
    if (landingPageConfig.toggleDayNight) {
      this.toggleNight(false);
    }
    if (landingPageConfig.color) {
      if (landingPageConfig.color.main.startsWith('#')) {
        const rgb = hexToRgb(landingPageConfig.color.main);
        document.documentElement.style.setProperty(
          '--main-color',
          rgb.r + ',' + rgb.g + ',' + rgb.b,
        );
      } else {
        document.documentElement.style.setProperty('--main-color', landingPageConfig.color.main);
      }
      if (landingPageConfig.color.dark.startsWith('#')) {
        const rgb = hexToRgb(landingPageConfig.color.dark);
        document.documentElement.style.setProperty(
          '--main-dark',
          rgb.r + ',' + rgb.g + ',' + rgb.b,
        );
      } else {
        document.documentElement.style.setProperty('--main-dark', landingPageConfig.color.dark);
      }
    }
    window.addEventListener('closeCall', () => {
      if (GlobalVars.socket && GlobalVars.admin) {
        GlobalVars.socket.emit('startLive', false);
        //GlobalVars.socket.emit('leaveRoom', GlobalVars.room);
      }
      //GlobalVars.room = undefined;
      GlobalVars.controller = undefined;
      const changeControllerEvent = new Event('changeController');
      window.dispatchEvent(changeControllerEvent);
      this.setLive(false);
      this.setCall(false, () => {
        GlobalVars.reset();
        if (window.location.search) {
          window.history.replaceState({}, document.title, '/');
        }
        enable();
      });
    });
    window.addEventListener('stopLive', () => {
      if (GlobalVars.socket) {
        GlobalVars.socket.emit('startLive', false);
        //GlobalVars.socket.emit('leaveRoom', GlobalVars.room);
      }
      //GlobalVars.room = undefined;
      GlobalVars.controller = undefined;
      const changeControllerEvent = new Event('changeController');
      window.dispatchEvent(changeControllerEvent);
      this.setLive(false);
    });
    window.addEventListener('groupCall', () => {
      const bearer = 'Bearer ' + getToken();
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: bearer },
      };
      fetch('https://twilio.alive.vision/api/user', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setState({ user: data });
          this.setCall(true);
        });
    });
    window.addEventListener('changeController', () => {
      if (GlobalVars.socket && this.state.live) {
        this.setState({ controlling: GlobalVars.controller });
      }
    });
    fetch('./ga/config.json')
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        ReactGA.initialize(data.gtag);
        ReactGA.pageview('/');
      })
      .catch(function (err) {
        console.log(err, ' error');
      });

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      let vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty('--vw', `${vw}px`);
      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        let vw = window.innerWidth * 0.01;
        document.documentElement.style.setProperty('--vw', `${vw}px`);
      });

      window.onorientationchange = () => {
        const sm = this.state.startingMapping ? this.state.startingMapping : '';
        sessionStorage.setItem('startingMapping', sm);
        this.setState({ orientation: window.orientation });
      };
      if (window.orientation === 0) {
        this.setState({ height: window.innerWidth - 86 + 'px' });
      } else {
        this.setState({ height: window.innerHeight });
      }
    }

    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const lang = urlParams.get('language');
      if (lang) {
        switch (lang) {
          case 'en':
            this.setState({ startingMapping: 'mapping_en.json', language: 'English' });
            break;

          case 'de':
            this.setState({ startingMapping: 'mapping_de.json', language: 'German' });
            break;

          default:
            window.location.replace(window.location.pathname);
            break;
        }
      }
      const code = urlParams.get('loc');
      if (code) {
        const values = decodeHash(code);
        this.setState({ startingMapping: values.mapping + '.json' });
      }
      const liveParam = urlParams.get('live');
      if (liveParam !== undefined && liveParam !== null) {
        const live = decodeNumber(liveParam);
        if (!GlobalVars.socket) {
          GlobalVars.socket = io('https://twilio.alive.vision');
          //GlobalVars.socket = io('http://localhost:3000');
        }
        if (!GlobalVars.socket.connected) {
          GlobalVars.socket.on('connect', () => {
            GlobalVars.socket?.emit('joinRoom', live);
            GlobalVars.room = Number(live);
            GlobalVars.socket?.on('participantsLimitReached', (reached: boolean) => {
              this.setState({ limitReached: reached });
            });
            GlobalVars.socket?.on('startLive', (start: boolean) => {
              this.setLive(start);
            });
            GlobalVars.socket?.on('mapping', (mapping: string) => {
              if (!this.state.startingMapping) {
                let mp = mapping;
                if (landingPageConfig.buttons && landingPageConfig.buttons.length > 0 && !mapping) {
                  mp = landingPageConfig.buttons[0].mapping;
                }
                this.setState({ startingMapping: mp });
              } else {
                if (!mapping) {
                  this.setState({ startingMapping: mapping });
                }
              }
            });
            this.setState({ live: this.state.startingMapping, lobby: true, videochat: true });
          });
        } else {
          GlobalVars.socket?.emit('joinRoom', live);
          GlobalVars.room = Number(live);
          GlobalVars.socket?.on('participantsLimitReached', (reached: boolean) => {
            this.setState({ limitReached: reached });
          });
          GlobalVars.socket?.on('startLive', (start: boolean) => {
            this.setLive(start);
          });
          GlobalVars.socket?.on('mapping', (mapping: string) => {
            if (!this.state.startingMapping) {
              let mp = mapping;
              if (landingPageConfig.buttons && landingPageConfig.buttons.length > 0 && !mapping) {
                mp = landingPageConfig.buttons[0].mapping;
              }
              this.setState({ startingMapping: mp });
            } else {
              if (!mapping) {
                this.setState({ startingMapping: mapping });
              }
            }
          });
          this.setState({ live: this.state.startingMapping, lobby: true, videochat: true });
        }
      }
    }

    if (landingPageConfig.defaultLanguage) {
      this.setState({ language: landingPageConfig.defaultLanguage });
    }
    if (landingPageConfig.backgroundImg) {
      this.setState({ bg: landingPageConfig.backgroundImg });
    }
    if (landingPageConfig.description) {
      this.setState({ description: landingPageConfig.description });
    }

    if (this.svgRef.current) {
      const children: any = this.svgRef.current.children;
      for (let child of children) {
        const info = this.getInfoOf(child.id);
        if (info) {
          child.onclick = () => {
            this.setState({ startingMapping: info.mapping });
          };
        }
      }
    }

    this.vidRef.current &&
      this.vidRef.current.addEventListener('timeupdate', () => {
        if (
          this.vidRef.current &&
          this.vidRef.current.currentTime > this.vidRef.current.duration - 2
        ) {
          this.vidRef.current.pause();
        }
      });
  };

  componentDidUpdate = (prevProps: any, prevState: LandingPageState) => {
    if (!prevState.live && this.state.live) {
      if (GlobalVars.socket) {
        GlobalVars.socket.on('mapping', (mapping: string) => {
          if (!this.state.startingMapping) {
            this.setState({ startingMapping: mapping });
          }
          if (!mapping) {
            this.setState({ startingMapping: mapping });
          }
        });
      }
    }
    //const landingPageConfig: LandingPageConfiguration = require('../JSON/landing_page.json');
    if (
      this.state.orientation === 0 &&
      prevState.orientation !== 0 &&
      this.state.height !== window.innerHeight + 'px'
    ) {
      this.setState({ height: window.innerHeight + 'px' });
    }
    if (this.state.startingMapping && this.state.language !== prevState.language) {
      //let configUrl = '.json';
      let mapping = this.state.startingMapping;
      /*switch (prevState.language) {
        case 'English':
          mapping = mapping.replace('_en.json', '');
          break;

        default:
          mapping = mapping.replace('.json', '');
          break;
      }
      switch (this.state.language) {
        case 'English':
          if (landingPageConfig.defaultLanguage === 'English') {
            configUrl = '.json';
          } else {
            configUrl = '_en.json';
          }
          break;

        default:
          configUrl = '.json';
          break;
      }
      mapping += configUrl;*/
      this.setState({ startingMapping: mapping });
    }

    if (this.svgRef.current) {
      const children: any = this.svgRef.current.children;
      for (let child of children) {
        const info = this.getInfoOf(child.id);
        if (info) {
          child.onclick = () => {
            if (this.state.live && this.state.controlling) {
              GlobalVars.socket?.emit('mapping', info.mapping);
            }
            this.setState({ startingMapping: info.mapping });
          };
        }
      }
    }
  };

  getInfoOf(id: string) {
    let configUrl: string = '.json';
    /*switch (this.state.language) {
      case 'English':
        configUrl = '_en.json';
        break;

      default:
        configUrl = '.json';
        break;
    }*/
    const landingPageConfig: LandingPageConfiguration = require(`../JSON/landing_page${configUrl}`);

    if (landingPageConfig.floors) {
      for (let floor of landingPageConfig.floors) {
        if (floor.svgId === id) {
          return floor;
        }
      }
    }

    return undefined;
  }

  toggleNight = (night: boolean) => {
    this.setState({ night: night });
  };

  returnToLanding = () => {
    if (this.state.live && this.state.controlling) GlobalVars.socket?.emit('mapping', null);
    this.setState({ startingMapping: null, bgIndex: 0 });
  };

  changeLanguage = (newLang: string | undefined) => {
    this.setState({ language: newLang });
  };

  setLive = (live: boolean | null, callback?: CallableFunction) => {
    this.setState({ live: live }, () => {
      if (callback) callback();
    });
  };

  setCall = (call: boolean, callback?: CallableFunction) => {
    this.setState({ videochat: call }, () => {
      if (callback) callback();
    });
  };

  toggleCall = () => {
    this.setState({ videochat: !this.state.videochat });
  };

  enterLive = (name: string) => {
    GlobalVars.identity = name;
    this.setState({ lobby: false });
  };

  render() {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let configUrl: string = '.json';
    let landingPageConfig: LandingPageConfiguration = require(`../JSON/landing_page${configUrl}`);
    /*switch (this.state.language) {
      case 'English':
        if (landingPageConfig.defaultLanguage === 'English') {
          configUrl = '.json';
        } else {
          configUrl = '_en.json';
        }
        break;

      default:
        configUrl = '.json';
        break;
    }*/

    landingPageConfig = require(`../JSON/landing_page${configUrl}`);

    if (this.state.startingMapping === 'form') {
      const mappingData: MappingData = require(`../JSON/${this.state.currentMapping}`);

      return (
        <FormPage
          click={() =>
            this.setState({
              startingMapping:
                this.state.currentMapping === 'mapping_student.json'
                  ? 'mapping_business.json'
                  : 'mapping_student.json',
              currentMapping:
                this.state.currentMapping === 'mapping_student.json'
                  ? 'mapping_business.json'
                  : 'mapping_student.json',
            })
          }
          description={
            landingPageConfig.form?.description ? landingPageConfig.form.description : ''
          }
          imgSrc={landingPageConfig.form?.backgroundImg ? landingPageConfig.form.backgroundImg : ''}
          logoSrc={landingPageConfig.pageLogo ? landingPageConfig.pageLogo : ''}
          title={landingPageConfig.form?.title ? landingPageConfig.form.title : ''}
          url={landingPageConfig.form?.url ? landingPageConfig.form.url : ''}
          clientEmail={
            landingPageConfig.form?.clientEmail ? landingPageConfig.form.clientEmail : ''
          }
          altCircuit={mappingData.sectors[0].name === 'Étudiant' ? 'Court Séjour' : 'Étudiant'}
        />
      );
    }

    let content;

    const backgrounds = landingPageConfig.buttons?.map((btn, index) => {
      let background = btn.backgroundImg;
      if (this.state.night && btn.backgroundImgN) {
        background = btn.backgroundImgN;
      }
      if (index + 1 === this.state.bgIndex) {
        return (
          <img
            key={`bg-key-${index + 1}`}
            src={background}
            style={{ opacity: 1, zIndex: 2 }}
            alt=""
          ></img>
        );
      } else {
        return (
          <img
            key={`bg-key-${index + 1}`}
            src={background}
            style={{ opacity: 0, zIndex: 1 }}
            alt=""
          ></img>
        );
      }
    });

    const brightness = this.state.bgIndex === 0 ? '' : 'brightness(0%)';
    backgrounds?.unshift(
      <img
        key={`bg-key-0`}
        src={
          this.state.night ? landingPageConfig.nightBackgroundImg : landingPageConfig.backgroundImg
        }
        style={{ opacity: 1, filter: brightness, zIndex: 0, transition: 'filter 0.3s ease-in' }}
        alt=""
      ></img>,
    );

    const circuits = landingPageConfig.buttons?.map((c) => {
      return { name: c.name, mapping: c.mapping };
    });

    if (this.state.limitReached) {
      return (
        <>
          <div className="limit-reached">
            Limite de participantes ultrapassado, não pode participar na chamada
          </div>
        </>
      );
    }

    /*const buttons = landingPageConfig.buttons?.map((btn, index) => {
      return (
        <button
          key={`btn-lp-${index}`}
          className="start"
          onClick={() => this.setState({ startingMapping: btn.mapping })}
        >
          {btn.name}
        </button>
      );
    });*/

    if (this.state.live) disable();
    if (mobile && !this.state.startingMapping) {
      console.log('Mobile');
    }
    const buttons = landingPageConfig.buttons?.map((b) => {
      return (
        <div
          key={'key-lp-' + b.name}
          className="pm-btn"
          onClick={() => {
            this.setState({ startingMapping: b.mapping, currentMapping: b.mapping });
          }}
        >
          {b.name}
        </div>
      );
    });
    content = !this.state.startingMapping ? (
      <div
        className="landing-page"
        style={{
          height: this.state.height,
          //backgroundImage: `url(${landingPageConfig.backgroundImg})`,
        }}
      >
        <img className="pm-bg" src={landingPageConfig.backgroundImg} alt=""></img>
        <div className="pm-focus"></div>
        <img className="pm-logo" src={landingPageConfig.pageLogo} alt="" />
        <h1 className="pm-title">{landingPageConfig.title}</h1>
        <p className="pm-desc">{landingPageConfig.description}</p>
        <div className="pm-explore">Explorer les espaces</div>
        <div className="pm-buttons">{buttons}</div>
        <img className="pm-partner" src={'./assets/img/MediContractGroup_logo_2000px.jpg'} alt="" />
      </div>
    ) : (
      [
        <ExplorerContainer
          key="ec"
          startingMapping={this.state.startingMapping}
          backFunction={this.returnToLanding}
          logo={landingPageConfig.logo}
          changeLanguage={
            landingPageConfig.languages && landingPageConfig.languages?.length > 0
              ? this.changeLanguage
              : undefined
          }
          languages={landingPageConfig.languages}
          language={this.state.language}
          aliveVision={landingPageConfig.aliveVision}
          showPartners={landingPageConfig.showPartners}
          orientation={this.state.orientation}
          webTitle={landingPageConfig.title ? landingPageConfig.title : landingPageConfig.webTitle}
          toggleNight={this.toggleNight}
          night={this.state.night}
          dropdownCircuits={landingPageConfig.dropdownCircuits}
          circuits={circuits}
          enableLiveMode={landingPageConfig.enableLiveMode}
          live={this.state.live}
          setLive={this.setLive}
          changeToForm={() => {
            this.setState({ startingMapping: 'form' });
          }}
        />,
        this.state.live &&
        !this.state.lobby &&
        !this.state.controlling &&
        GlobalVars.room !== undefined ? (
          <>
            <div
              key="ri"
              className="remote-indicator"
              onClick={() => {
                this.setState({ blocked: true });
              }}
            ></div>
            {this.state.blocked && (
              <div className="block-background">
                <div className="block-container">
                  <InfoSVG />
                  <p>Não é possível controlar a visita enquanto está a ser guiado.</p>
                  <div className="buttons">
                    <div
                      className="cancel"
                      onClick={() => {
                        this.setState({ blocked: false });
                      }}
                    >
                      CONTINUAR
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : null,
        this.state.lobby && !this.state.controlling && GlobalVars.room !== undefined ? (
          <>
            <div className="lobby-bg"></div>
            <Lobby key="lobby-key" startCall={this.enterLive} />
          </>
        ) : null,
        this.state.live && this.state.controlling && GlobalVars.room !== undefined ? (
          <div key="ssi" className="stop-sharing-indicator"></div>
        ) : null,
        this.state.videochat &&
        !this.state.lobby &&
        ((GlobalVars.admin && this.state.user) || !GlobalVars.admin) ? (
          <VideoChat key="vc" live={this.state.live} user={this.state.user} />
        ) : null,
      ]
    );

    return content;
  }
}
