import React, { FunctionComponent } from 'react';
import CSS from 'csstype';
import './CustomLinkHotspot.css';
import { ReactComponent as NextSpaceSVG } from '../icon/type=next-space-up.svg';

interface CustomLinkHotspotProps {
  className: string;
  style: CSS.Properties;
  onClick: CallableFunction;
  onContextMenu: CallableFunction;
  icon: string;
}

const CustomLinkHotspot: FunctionComponent<CustomLinkHotspotProps> = ({
  className,
  style,
  onClick,
  onContextMenu,
  icon,
}) => {
  return (
    <div
      style={{ ...style }}
      className={'custom-link-hotspot ' + className}
      onClick={() => onClick()}
      onContextMenu={() => onContextMenu()}
    >
      <NextSpaceSVG />
      <i className="material-icons">{icon}</i>
    </div>
  );
};

export default CustomLinkHotspot;
